<!--
 * @Description: 客户常用地址弹框
 * @Author: ChenXueLin
 * @Date: 2022-03-16 14:21:41
 * @LastEditTime: 2022-07-15 15:52:35
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="选择常用地址"
      :visible="selectAddressDialog"
      width="800px"
      v-loading="dialogLoading"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="install-scene-dialog"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i
            class="e6-icon-add_line"
            title="新增常用地址"
            @click="handleAdd()"
          ></i>
          <i
            class="e6-icon-clear_line"
            title="删除常用地址"
            @click="handleDelete"
          ></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="contactAddrName">
              <el-input
                v-model="searchForm.contactAddrName"
                placeholder="客户地址"
                title="客户地址"
                maxlength="100"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="createTime"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          ref="tableList"
          border
          height="300"
          :data="tableData"
          highlight-current-row
          @select="dialogCheck"
        >
          <!--   @selection-change="handleSelectionChange" -->
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirmAddress">确定</el-button>
      </div>
    </el-dialog>
    <!-- 新增地址 -->
    <AddCustomerCommomAddress
      ref="AddCustomerCommomAddress"
      :corpId="corpId"
      :corpName="corpName"
      v-if="AddCustomerCommomAddressVisible"
      @updateList="handleSearch"
    ></AddCustomerCommomAddress>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import { getCorpAddrListPage, corpAddrDelete } from "@/api";
import AddCustomerCommomAddress from "@/components/AddCustomerCommomAddress";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "customerAddress",
  components: { AddCustomerCommomAddress },
  data() {
    return {
      searchForm: {
        corpId: "", //客户ID
        contactAddrName: "", //客户地址
        createTime: [],
        createdTimeStart: "", //开始时间
        createdTimeEnd: "", //结束时间
        pageIndex: 1,
        pageSize: 20
      },
      selectioned: {}, //单选中的值
      dialogLoading: false,
      AddCustomerCommomAddressVisible: false,
      queryListAPI: getCorpAddrListPage,
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      columnData: [
        {
          fieldName: "corpName",
          fieldLabel: "客户名称",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "contactAddrName",
          fieldLabel: "联系地址",
          width: 240,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          fieldLabel: "备注",
          width: 160,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdName",
          fieldLabel: "创建人",
          width: 140,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          fieldLabel: "创建时间",
          width: 160,
          fixed: false,
          align: "center"
        }
      ], //表头
      total: 0,
      tableData: [] // 表格数据
    };
  },
  mixins: [base, listPage, listPageReszie],
  props: ["selectAddressDialog", "corpId", "corpName"],
  computed: {},
  watch: {
    selectAddressDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.searchForm.corpId = this.corpId;
          this.searchForm.corpName = this.corpName;
          this.handleSearch();
        }
      }
    },
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    }
  },
  methods: {
    //获取列表数据 这里只写了分页的 非分页的可自行在组件内覆盖
    async queryList() {
      try {
        this.dialogLoading = true;
        let res = await this.queryListAPI(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.elementTableDoLayout();
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    dialogCheck: function(selection, row) {
      this.$refs.tableList.clearSelection();
      if (selection.length === 0) {
        // 判断selection是否有值存在
        return;
      }
      if (row) {
        this.selectioned = row;
        console.log(this.selectioned, "---");
        this.$refs.tableList.toggleRowSelection(row, true);
      }
    },

    // 表格多选
    handleSelectionChange(columns) {
      console.log(columns, this.selectioned);
      //   this.selColumnId = columns.map(item => {
      //     return item.companyId;
      //   });
    },
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.handleAdd(row);
      }
    },
    //批量删除
    handleDelete() {
      if (!this.selectioned.corpId) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除所选常用地址?", "删除常用地址", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.corpAddrDelete();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //删除客户请求
    async corpAddrDelete() {
      try {
        this.loading = true;
        let res = await corpAddrDelete([this.selectioned]);
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleAdd(row) {
      this.AddCustomerCommomAddressVisible = true;
      this.$nextTick(() => {
        this.$refs.AddCustomerCommomAddress.init(row);
      });
    },
    handleClose() {
      this.selectioned = {};
      this.searchForm = {
        corpId: "", //客户ID
        contactAddrName: "", //客户地址
        createTime: [],
        createdTimeStart: "", //开始时间
        createdTimeEnd: "", //结束时间
        pageIndex: 1,
        pageSize: 20
      };
      this.$emit("handleClose", "selectAddressDialog");
    },
    confirmAddress() {
      if (!this.selectioned.corpId) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$emit("updateAddress", this.selectioned);
      this.handleClose();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/ .el-table__header {
  .cell {
    .el-checkbox {
      display: none;
    }
  }
}
</style>
